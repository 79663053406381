import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "WatFocusTesterInfo" }
const _hoisted_2 = { class: "WatFocusTesterInfo--Group" }
const _hoisted_3 = { class: "WatFocusTesterInfo--Actions" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Input, {
        label: _ctx.$t('wat_focus_tester_email'),
        modelValue: _ctx.form.mail,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.mail) = $event)),
        placeholder: 'Ej: adrian.fernandez@gmail.com'
      }, null, 8 /* PROPS */, ["label", "modelValue", "placeholder"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Input, {
        label: _ctx.$t('wat_focus_tester_nick'),
        modelValue: _ctx.form.nickname,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.nickname) = $event)),
        placeholder: 'Ej: adrian89'
      }, null, 8 /* PROPS */, ["label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Imput_genderquestion')), 1 /* TEXT */),
      _createElementVNode("div", null, [
        _createElementVNode("p", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.gender = 'M')),
          class: _normalizeClass({'selected':_ctx.form.gender == 'M'})
        }, _toDisplayString(_ctx.$t('Hombre')), 3 /* TEXT, CLASS */),
        _createElementVNode("p", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.gender = 'W')),
          class: _normalizeClass({'selected':_ctx.form.gender == 'W'})
        }, _toDisplayString(_ctx.$t('Mujer')), 3 /* TEXT, CLASS */)
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Input, {
        label: _ctx.$t('wat_focus_tester_age'),
        modelValue: _ctx.form.bornDate,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.bornDate) = $event)),
        type: "number",
        placeholder: 'Ej: 32'
      }, null, 8 /* PROPS */, ["label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('back')))
      }, _toDisplayString(_ctx.$t('wat_focus_tester_back_calendar')), 1 /* TEXT */),
      _createElementVNode("button", {
        disabled: _ctx.disabled,
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save()))
      }, _toDisplayString(_ctx.$t('wat_focus_tester_confirm')), 9 /* TEXT, PROPS */, _hoisted_4)
    ])
  ]))
}